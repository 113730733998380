import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@services/header.service';
import { combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { GridsterItem } from 'angular-gridster2';
import { MatIconModule } from '@angular/material/icon';
import { Building } from '@app/shared/models/building.interface';
import { DashboardService } from '@services/dashboard.service';
import { map, shareReplay } from 'rxjs/operators';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { Tab } from '@components/tabs/tab.interface';
import { DashboardFilterComponent } from '@app/dashboard/dashboard-filter/dashboard-filter.component';
import { User } from '@app/shared/models/user.interface';
import { DashboardTabs } from '@app/dashboard/dashboard-config';
import { DashboardStateService } from '@app/dashboard/dashboard-filter/dashboard-state.service';
import { SensorNodeService } from '@services/sensor-node.service';
import { Tag } from '@app/shared/models/tag.interface';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, MatIconModule, SharedComponentsModule, RouterOutlet, DashboardFilterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {
  building: Building;
  tabs$: Observable<Tab[]>;
  private readonly destroy$ = new Subject<void>();

  @Output() resizeEvent = new EventEmitter<GridsterItem>();

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly dashboardStateService: DashboardStateService,
    private readonly header: HeaderService,
    private readonly navService: NavigationService,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly nodeService: SensorNodeService
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.hideFloorsMenu();
    this.nodeService.resetNodesWithTags();
    this.initTabs();
  }

  ngOnDestroy(): void {
    this.dashboardService.resetDashboard();
    this.nodeService.resetNodesWithTags();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initTabs(): void {
    this.tabs$ = this.route.params.pipe(
      switchMap((params) =>
        combineLatest([
          this.userService.getCurrentUser(),
          this.userService.getBuilding(params.buildingId),
          this.userService.getFloorsForBuilding(params.buildingId),
          this.getSelectableBuildingTags(params.buildingId)
        ])
      ),
      map(([user, building, floors, tags]) => {
        this.dashboardStateService.initializeState({ floors, tags, building });
        this.building = building;
        this.navService.initNavigation(window.location.href, building);
        return this.createDashboardTabMenu(user, building);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private getSelectableBuildingTags(buildingId: number): Observable<Tag[]> {
    return this.nodeService.fetchNodesWithTagsForBuilding(buildingId).pipe(
      map((nodesWithTag) => {
        const selectableTags: Tag[] = [];
        nodesWithTag.forEach((n) =>
          n.tags?.forEach((tag: Tag) => {
            if (!selectableTags.some((t) => t.id == tag.id)) {
              selectableTags.push(tag);
            }
          })
        );
        return selectableTags;
      })
    );
  }

  private createDashboardTabMenu(user: User, building: Building): Tab[] {
    return DashboardTabs.filter((tab) => tab.isAuthorized(user, building)).map((tab) => {
      const clonedTab = { ...tab };
      clonedTab.path = tab.path.replace(/\{buildingId}/i, String(building.id));
      return clonedTab;
    });
  }
}

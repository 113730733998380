import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from 'src/environments/environment.interface';
import { Building } from '../models/building.interface';
import { Resource } from './resource';
import { IResource } from './resource.interface';
import { Observable } from 'rxjs';
import { BuildingValidationResponse } from '@app/shared/models/building-validation-response.interface';

@Injectable({
  providedIn: 'root'
})
export class BuildingResource extends Resource<Building, number> implements IResource<Building, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/building`);
  }

  public getBuildingsByIds(ids: number[]): Observable<Building[]> {
    let httpParams: HttpParams = new HttpParams();
    ids.forEach((id) => {
      httpParams = httpParams.append('ids', String(id));
    });
    return this.httpClient.get<Building[]>(this.baseUrl, { params: httpParams });
  }

  public getBuildings(): Observable<Building[]> {
    return this.httpClient.get<Building[]>(this.baseUrl);
  }

  public getAllBuildingIdsWithNames(): Observable<{ id: number; name: string }[]> {
    return this.httpClient.get<{ id: number; name: string }[]>(`${this.baseUrl}/all-ids-with-names`);
  }

  validateBuilding(building: Building): Observable<BuildingValidationResponse> {
    return this.httpClient.post<BuildingValidationResponse>(`${this.baseUrl}/validate`, building);
  }
}

import { CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { MappingService } from '@services/mapping.service';
import { Sort, SortDirection } from '@angular/material/sort';
import { SensorNode } from '@app/shared/models/sensor-node';

export interface UnmappedNode {
  nodeId: number;
  address: number;
  nodeType: string;
  gatewayName: string;
  gatewayId: number;
  buildingId: number;
  isConnected: boolean;
}

export interface NodePoint extends UnmappedNode {
  dropPoint: { x: number; y: number };
}

export abstract class AbstractUnmappedNodesDataSource {
  protected nodesSubject = new BehaviorSubject<UnmappedNode[]>([]);
  protected loadingSubject = new BehaviorSubject(false);
  protected countSubject = new BehaviorSubject(0);

  connect(collectionViewer: CollectionViewer): Observable<readonly UnmappedNode[]> {
    return this.nodesSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.nodesSubject.next([]);
    this.countSubject.next(0);
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  abstract loadUnmappedNodes(buildingId: number): void;

  sortUnderlyingData(event: Sort): void {
    const { active, direction } = event;
    if (direction !== '') {
      const renderedData = this.nodesSubject.getValue();
      const sortedData = [
        ...renderedData.sort((left, right) => this.sortByKeyAndDirection(active, direction, left, right))
      ];
      this.nodesSubject.next(sortedData);
    }
  }

  private sortByKeyAndDirection(
    keyName: string,
    direction: SortDirection,
    left: UnmappedNode,
    right: UnmappedNode
  ): number {
    if (direction === 'asc') {
      if (left[keyName] < right[keyName]) {
        return -1;
      } else if (left[keyName] > right[keyName]) {
        return 1;
      }
      return 0;
    }
    if (direction === 'desc') {
      if (right[keyName] < left[keyName]) {
        return -1;
      } else if (right[keyName] > left[keyName]) {
        return 1;
      }
      return 0;
    }
  }
}

export class UnmappedNodesDataSource extends AbstractUnmappedNodesDataSource {
  private MIN_DELAY_FOR_LOADING_SPINNER = 750;
  constructor(private readonly mappingService: MappingService) {
    super();
  }

  loadUnmappedNodes(buildingId: number): void {
    this.loadingSubject.next(true);
    this.mappingService
      .getUnmappedNodesList(buildingId)
      .pipe(delay(this.MIN_DELAY_FOR_LOADING_SPINNER))
      .subscribe((nodes) => {
        this.nodesSubject.next(nodes);
        this.loadingSubject.next(false);
        this.countSubject.next(nodes.length);
      });
  }
}

export class MockDataSource extends AbstractUnmappedNodesDataSource {
  private MIN_DELAY_FOR_LOADING_SPINNER = 2200;
  constructor() {
    super();
  }

  private generateNodes(count: number, buildingId: number): UnmappedNode[] {
    const result: UnmappedNode[] = [];
    const nodeTypes = [
      SensorNode.SN3_NODE_TYPE,
      SensorNode.PASSIVE_NODE_TYPE,
      SensorNode.HIM84_NODE_TYPE,
      SensorNode.HCD405_NODE_TYPE,
      SensorNode.HCD038_NODE_TYPE
    ];
    while (count > 0) {
      const nodeType = nodeTypes[Math.floor(Math.random() * 3)];
      const address = Math.floor(Math.random() * 12000);
      const gatewayId = Math.floor(Math.random() * 500);
      const gatewayName = 'Gateway-' + gatewayId;
      const isConnected = true;
      result.push({ nodeType, address, gatewayId, gatewayName, buildingId, nodeId: address, isConnected });
      count--;
    }
    return result;
  }

  loadUnmappedNodes(buildingId: number): void {
    this.loadingSubject.next(true);
    const delayedObservable = of(this.generateNodes(150, buildingId)).pipe(delay(this.MIN_DELAY_FOR_LOADING_SPINNER));
    delayedObservable.subscribe((result) => {
      this.loadingSubject.next(false);
      this.nodesSubject.next(result);
      this.countSubject.next(result.length);
    });
  }
}

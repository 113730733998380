import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { ScheduleService } from '@services/schedules.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { DisplayTags } from '@app/shared/models/tag.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Schedule } from '@app/shared/models/schedule';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatInput, MatLabel, MatSuffix } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { Rule } from '@app/shared/models/rule';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { NgClass } from '@angular/common';
import { ITimeOption } from '@app/shared/models/time-option.interface';
import { ScheduleRuleComponent } from '@app/schedules/schedule-rule/schedule-rule.component';
import { IScheduleDateForm } from '@app/shared/models/schedule-form.interface';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { Building } from '@app/shared/models/building.interface';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-dated-schedule',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatInput,
    MatOption,
    MatSelect,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatLabel,
    MatFormField,
    NgClass,
    ReactiveFormsModule,
    MatSuffix,
    ScheduleRuleComponent,
    MatIcon,
    MatTooltip,
    NotificationBlockComponent,
    MatNativeDateModule
  ],
  templateUrl: './form-dated-schedule.component.html',
  styleUrl: './form-dated-schedule.component.scss'
})
export class FormDatedScheduleComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private dialog: ConfirmationDialogService
  ) {}

  @Input() schedule: Schedule;
  @Input() isNew: boolean;
  @Input() tags$: Observable<DisplayTags>;
  @Input() building: Building;

  @Output() onDeleteSchedule = new EventEmitter<number>();
  @Output() onSaveNewSchedule = new EventEmitter<number>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<Schedule>();
  @Output() initialized = new EventEmitter<void>();
  scheduleForm: FormGroup<IScheduleDateForm>;
  saveLabel: string;
  readonly floors: Floor[] = [];
  minSelectableStartDate: Date;
  maxSelectableStartDate: Date;
  minSelectableEndDate: Date;
  maxSelectableEndDate: Date;
  currentDate: Date;
  timeOptions: ITimeOption[];
  StatusClass = StatusClass;

  ngOnDestroy(): void {}

  ngOnInit(): void {
    if (!this.schedule.id) {
      this.isNew = true;
    }
    this.saveLabel = 'Save';
    this.currentDate = TimeUtils.convertTimezone(
      new Date(),
      TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
    );
    this.minSelectableStartDate =
      this.isNew || this.schedule.startDate > this.currentDate ? this.currentDate : this.schedule.startDate;
    this.minSelectableEndDate =
      this.isNew || this.schedule.endDate > this.currentDate ? this.currentDate : this.schedule.endDate;
    this.timeOptions = this.produceTimeOptions(this.currentDate);
    if (!this.schedule.startDate) {
      this.schedule.startDate = new Date(this.currentDate.getTime());
      this.schedule.endDate = new Date(this.schedule.startDate.getTime());
    }
    this.initFormControls();
  }

  initFormControls(): void {
    this.scheduleForm = this.fb.group({
      name: [this.schedule.name || '', Validators.required],
      startDate: new FormControl(new Date(this.schedule.startDate.getTime()), {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      startTime: [
        this.timeOptions.find((element) => this.schedule.startTime === element?.timeString),
        [Validators.required]
      ],
      endDate: new FormControl(new Date(this.schedule.endDate.getTime()), {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      endTime: [
        this.timeOptions.find((element) => this.schedule.endTime === element?.timeString),
        [Validators.required]
      ]
    });
    this.initialized.emit();
  }

  delete(): void {
    const data = new ConfirmDialogData(`Delete schedule ${this.schedule.name}?`, 'Delete Schedule');
    this.dialog.open(data).subscribe({
      next: (result) => {
        if (result && this.schedule.id != null) {
          this.scheduleService.deleteSchedule(this.schedule).subscribe({
            next: () => {
              this.onDeleteSchedule.emit(this.schedule.id);
            }
          });
        } else {
          return;
        }
      }
    });
  }

  close(): void {
    this.onClose.emit();
  }

  save(): void {
    if (this.scheduleForm.invalid) {
      return;
    }

    this.saveLabel = 'Saving...';

    this.schedule.name = this.scheduleForm.get('name').value;
    if (this.schedule.id != null) {
      this.scheduleService.updateSchedule(Schedule.toDto(this.schedule)).subscribe({
        next: (schedule) => {
          this.schedule = Schedule.clone(Schedule.fromDto(schedule));
          this.close();
          this.saveLabel = 'Save';
        }
      });
    } else {
      this.scheduleService.saveSchedule(Schedule.toDto(this.schedule), this.building.id).subscribe({
        next: (schedule) => {
          this.schedule = Schedule.clone(schedule);
          this.onSaveNewSchedule.emit();
          this.saveLabel = 'Save';
        }
      });
    }
  }

  validateRules(): boolean {
    if (!this.schedule?.rules?.length) {
      return false;
    }

    return this.schedule.rules.every((rule) => {
      return rule.command && rule.value;
    });
  }

  addRule(): void {
    this.schedule.rules.push(new Rule());
  }

  private produceTimeOptions(date: Date): ITimeOption[] {
    const timeOptions: ITimeOption[] = [];
    for (let hours = 0; hours < 24; hours += 1) {
      for (let minutes = 0; minutes < 60; minutes += 10) {
        timeOptions.push({
          timeString: [('0' + hours).slice(-2), ('0' + minutes).slice(-2)].join(':'),
          hour: hours,
          minute: minutes,
          disabled: false
        });
      }
    }
    return timeOptions;
  }

  removeRule(index: number): void {
    this.schedule.rules.splice(index, 1);
  }

  onChangeStartDate(): void {
    this.minSelectableEndDate = this.scheduleForm.value.startDate;
    if (this.scheduleForm.value.endDate < this.minSelectableEndDate) {
      this.scheduleForm.get('endDate').setValue(new Date(this.minSelectableEndDate));
    }
    this.schedule.startDate = this.scheduleForm.get('startDate').getRawValue();
  }

  onChangeEndDate(): void {
    this.maxSelectableStartDate = this.scheduleForm.value.endDate;
    this.schedule.endDate = this.scheduleForm.get('endDate').getRawValue();
  }

  onChangeStartTime(): void {
    this.schedule.startTime = this.scheduleForm.get('startTime').getRawValue().timeString;
    this.onChange.emit(this.schedule);
  }

  onChangeEndTime(): void {
    this.schedule.endTime = this.scheduleForm.get('endTime').getRawValue().timeString;
    this.onChange.emit(this.schedule);
  }

  validateDateAndTime(): boolean {
    const endDate = this.scheduleForm.value.endDate.getTime();
    const startDate = this.scheduleForm.value.startDate.getTime();
    const startTime = this.scheduleForm.value.startTime.timeString;
    const endTime = this.scheduleForm.value.endTime.timeString;
    return endDate > startDate || (endDate === startDate && endTime > startTime);
  }

  get startDate(): AbstractControl {
    return this.scheduleForm.get('startDate');
  }
}

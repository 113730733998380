import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TestsSummary } from '@services/floorplan.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-failed-tests-counter',
  standalone: true,
  templateUrl: './failed-tests-counter.component.html',
  styleUrls: ['./failed-tests-counter.component.scss'],
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailedTestsCounterComponent {
  @Input()
  showCounter: boolean;
  @Input()
  testsSummary: TestsSummary;

  allTestsPassed(): boolean {
    return this.testsSummary.failedFunctionTests === 0 && this.testsSummary.failedDurationTests === 0;
  }

  getFailedTestsCount(): number {
    return this.testsSummary.failedDurationTests + this.testsSummary.failedFunctionTests;
  }

  getCancelledTestsCount(): number {
    return this.testsSummary.cancelledFunctionTests + this.testsSummary.cancelledDurationTests;
  }

  noTestsCancelled(): boolean {
    return this.testsSummary.cancelledDurationTests + this.testsSummary.cancelledFunctionTests === 0;
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { TagChangeEvent, TagsComponent } from '@components/tags/tags.component';
import { Building } from '@app/shared/models/building.interface';
import { HeaderService } from '@services/header.service';
import { BehaviorSubject, combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { ConfigurationComponent } from '@app/beacons/configuration/configuration.component';
import { FloorplanTag, tagToFloorplanTag } from '@app/shared/models/tag.interface';
import { map, takeUntil } from 'rxjs/operators';
import { SensorNodeService } from '@services/sensor-node.service';
import { SelectableNode, SensorNode } from '@app/shared/models/sensor-node';
import { FloorplanService } from '@services/floorplan.service';
import { BeaconSettingService } from '@services/beacon-setting.service';
import { BeaconSetting } from '@app/shared/models/beacons-setting.interface';
import { ToastService } from '@services/toast/toast.service';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { NodeListCardComponent } from '@components/node-list-card/node-list-card.component';
import { FloorplanComponent } from '@components/floorplan/floorplan.component';

@Component({
  selector: 'app-beacons',
  standalone: true,
  imports: [
    CommonModule,
    ConfigurationComponent,
    MatDrawer,
    MatDrawerContainer,
    PanelToggleComponent,
    SharedComponentsModule,
    TagsComponent,
    NodeListCardComponent,
    FloorplanComponent
  ],
  templateUrl: './beacons.component.html',
  styleUrl: './beacons.component.scss'
})
export class BeaconsComponent implements OnInit {
  building: Building;
  floorId: number;
  tags$: Observable<FloorplanTag[]>;
  isLeftColHidden = false;
  isRightColHidden = false;
  readonly sortByFields = {
    id: 'Id',
    address: 'Address',
    'beaconSetting.enabled': 'Enabled',
    'beaconSetting.powerLevel': 'Power Level',
    'beaconSetting.content': 'Beacon Content',
    'beaconSetting.beaconInterval': 'Beacon Repetition'
  };

  private pendingNodes: number[] = [];
  private timedOutNodes: number[] = [];
  private selectedSensorNodes: SensorNode[] = [];
  private RETRIES = 7;
  private delayBetweenRetries = 5000;
  private destroyed$ = new Subject<void>();
  private tagsPanelToggled$ = new BehaviorSubject<void>(null);

  constructor(
    private header: HeaderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private navService: NavigationService,
    private snService: SensorNodeService,
    private toast: ToastService,
    private floorplanService: FloorplanService,
    private beaconSettingService: BeaconSettingService
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.showFloorsMenu();
    this.setBuildingIdAndFloorId();
  }

  private setBuildingIdAndFloorId(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.floorId = params.floorId;
          this.loadTags();
          return this.userService.getBuilding(params.buildingId);
        })
      )
      .subscribe((building) => {
        this.navService.initNavigation(window.location.href, building);
        this.building = building;
      });
  }

  public querySelectedNodes(): void {
    this.selectedSensorNodes = this.snService.selectedEntities as SensorNode[];
    const nodeIds = this.getSelectedNodeIds();
    this.addToPendingMultipleNodes(nodeIds);
    this.addToTimedOutNodes(nodeIds);
    this.beaconSettingService.querySensorNodes(nodeIds, this.building.id).subscribe({
      next: () => {
        this.getNodeWithRetries(nodeIds, [], this.RETRIES);
      },
      error: () => {
        this.toast.error({ message: 'Something went wrong!', dataCy: 'error' });
        this.removeFromPendingNodes(nodeIds);
        this.removeFromTimedOutNodes(nodeIds);
      }
    });
  }

  private getNodeWithRetries(nodeIds: number[], confirmedNodeIds: number[], retries: number): void {
    let retry = false;
    nodeIds = nodeIds.filter((currentItem) => !confirmedNodeIds.includes(currentItem));

    if (retries <= 0) {
      if (confirmedNodeIds.length) {
        this.showUpdatedInformation(confirmedNodeIds);
      } else {
        this.toast.info({ message: 'The time expired with no result', dataCy: 'time-expired' });
      }
      this.removeFromPendingNodes(nodeIds);
      this.removeFromTimedOutNodes(nodeIds);
      return;
    }

    this.beaconSettingService.getBeaconSettingsForFloorAndNodeIds(this.floorId, nodeIds).subscribe({
      next: (result: BeaconSetting[]) => {
        result.forEach((beaconSetting) => {
          const node = this.selectedSensorNodes.find((node: SensorNode) => node.id === beaconSetting.sensorNodeId);
          if (beaconSetting && beaconSetting.updateStatus === 'CONFIRMED') {
            this.removeFromPendingNodes([beaconSetting.sensorNodeId]);
            this.updateNodeAndPanel(node, beaconSetting);
            confirmedNodeIds.push(beaconSetting.sensorNodeId);
            this.removeFromTimedOutNodes([beaconSetting.sensorNodeId]);
          } else {
            this.updateNodeAndPanel(node, beaconSetting);
            retry = true;
          }
        });
        if (retry) {
          setTimeout(() => {
            this.getNodeWithRetries(nodeIds, confirmedNodeIds, retries - 1);
          }, this.delayBetweenRetries);
        } else {
          this.showUpdatedInformation(confirmedNodeIds);
        }
      },
      error: () => {
        this.toast.error({ message: 'Error in communication with the Portal', dataCy: 'error-portal-communication' });
        this.removeFromPendingNodes(nodeIds);
        this.removeFromTimedOutNodes(nodeIds);
      }
    });
  }

  private getSelectedNodeIds(): number[] {
    return this.selectedSensorNodes.map((node: SensorNode): number => node.id);
  }

  private removeFromPendingNodes(ids: number[]): void {
    this.pendingNodes = this.pendingNodes.filter((node) => !ids.includes(node));
  }

  private removeFromTimedOutNodes(ids: number[]): void {
    this.timedOutNodes = this.timedOutNodes.filter((node) => !ids.includes(node));
  }

  private updateNodeAndPanel(node: SensorNode, updatedSetting: BeaconSetting): void {
    this.beaconSettingService.updateSettingForSelectedNode(updatedSetting);
    node.beaconSetting = updatedSetting;
  }

  public get isSelectedNodeReady(): boolean {
    this.selectedSensorNodes = this.snService.selectedEntities as SensorNode[];
    return (
      !this.isSelectedNodePending() &&
      this.selectedSensorNodes.length > 0 &&
      !this.selectedSensorNodes.some((node) => {
        return !node.properlyMapped || !node.connected || node.isPassiveNode;
      })
    );
  }

  public isSelectedNodePending(): boolean {
    let isPending = false;
    if (this.selectedSensorNodes.length > 0) {
      isPending = this.selectedSensorNodes.some((node) => this.isNodePending(node.id));
    }
    return isPending;
  }

  private showUpdatedInformation(confirmedNodeIds: number[]): void {
    if (confirmedNodeIds && confirmedNodeIds.length > 0) {
      if (confirmedNodeIds.length === 1 && this.selectedSensorNodes.length === 1) {
        this.toast.success({
          message: `The data was updated for node  ${confirmedNodeIds}`,
          dataCy: 'success-updated-information'
        });
      } else {
        const timeOutMessage = this.timedOutNodes.length
          ? '\nThe time expired with no result for  ' + this.timedOutNodes.sort()
          : '';
        this.toast.success({
          message: `The data was updated for node  ${confirmedNodeIds} ${timeOutMessage}`,
          dataCy: 'success-timeout-information'
        });
      }
    }
  }

  private addToPendingMultipleNodes(ids: number[]): void {
    ids.map((id) => {
      if (!this.isNodePending(id)) {
        this.pendingNodes.push(id);
      }
    });
  }

  private addToTimedOutNodes(ids: number[]): void {
    ids.forEach((id) => {
      if (this.isNodePending(id)) {
        this.timedOutNodes.push(id);
      }
    });
  }

  public isNodePending(id: number): boolean {
    return this.pendingNodes.indexOf(id, 0) > -1;
  }

  private loadTags(): void {
    this.tags$ = combineLatest([this.snService.getCurrentFloorNodes$(), this.tagsPanelToggled$]).pipe(
      takeUntil(this.destroyed$),
      map(([nodes, _]) => {
        const tagMap = new Map<number, FloorplanTag>();
        nodes.forEach((node) => {
          if (node.tags) {
            node.tags.forEach((tag) => {
              tagMap.set(tag.id, tagToFloorplanTag(tag));
            });
          }
        });
        return [...tagMap.values()];
      })
    );
  }

  handleTagPanelToggle(drawerRef: MatDrawer): void {
    this.tagsPanelToggled$.next();
    drawerRef.toggle();
  }

  toggleTag(event: TagChangeEvent): void {
    const tag = event.tag;
    tag.isActive = event.isActive;
    this.floorplanService.tagToggled(tag);
  }

  toggleLeft(val: boolean): void {
    this.isLeftColHidden = val;
  }

  toggleRight(val: boolean): void {
    this.isRightColHidden = val;
  }

  nodeClick(node: SelectableNode): void {
    if (this.floorplanService.isCumulativeModeActive) {
      this.snService.toggleEntitySelection(node, this.floorplanService.isCumulativeModeActive);
    } else {
      this.snService.selectEntitiesInArea([node], this.floorplanService.isCumulativeModeActive);
    }
    this.snService.updateQueryParams();
  }

  get nodeListPanelClass(): string {
    if (this.isLeftColHidden) {
      return 'hidden';
    } else if (this.isRightColHidden) {
      return 'col-span-6';
    } else {
      return 'col-span-4';
    }
  }

  get floorPanelClass(): string {
    if (this.isLeftColHidden && this.isRightColHidden) {
      return 'col-span-12';
    } else if (this.isLeftColHidden) {
      return 'col-span-10';
    } else if (this.isRightColHidden) {
      return 'col-span-6';
    } else {
      return 'col-span-6 ';
    }
  }

  get configPanelClass(): string {
    return `${this.isRightColHidden ? 'hidden' : 'col-span-2'}`;
  }
}

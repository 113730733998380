import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { Observable } from 'rxjs';
import { CheckboxState, FloorplanTag, Tag, TagType } from '@app/shared/models/tag.interface';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityService } from '@services/security.service';
import { TagService } from '@services/tag.service';
import { CreateTagFormComponent } from '@components/tags/create-tag-form/create-tag-form.component';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { EditTagFormComponent } from '@components/tags/edit-tag-form/edit-tag-form.component';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';

@Component({
  standalone: true,
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  imports: [
    CreateTagFormComponent,
    AuthorizationModule,
    SharedComponentsModule,
    EditTagFormComponent,
    NotificationBlockComponent
  ],
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  @ViewChild('tagList') tagList: ElementRef;

  @Input() buildingId: number;
  @Input() isEditable: boolean;
  @Input() isCreateEnabled: boolean;
  @Input() isNodeTaggingModeActive: boolean;
  @Input() tags$: Observable<FloorplanTag[]>;

  @Output() tagCreate = new EventEmitter<FloorplanTag>();
  @Output() tagSelectionChange = new EventEmitter<TagChangeEvent>();
  @Output() tagEditChange = new EventEmitter<FloorplanTag>();
  @Output() tagDeleted = new EventEmitter<FloorplanTag>();
  @Output() tagReset = new EventEmitter<FloorplanTag>();
  @Output() tagUpdate = new EventEmitter<FloorplanTag>();

  isBusy = true;
  hasManageTenantForBuilding = false;

  constructor(
    private readonly buildingService: UserService,
    private navigationService: NavigationService,
    private securityService: SecurityService,
    private tagService: TagService,
    private detectorRef: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.detectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.init();
    this.buildingService.getBuilding(this.buildingId).subscribe((building) => {
      this.navigationService.initNavigation(window.location.href, building);
    });
  }

  private init(): void {
    this.isBusy = false;
  }

  getCheckboxClass(tag: FloorplanTag): string {
    if (this.isNodeTaggingModeActive) {
      if (tag.checked === CheckboxState.CHECKED) {
        return 'or-checked';
      } else if (tag.checked === CheckboxState.MIXED) {
        return 'or-mixed';
      } else {
        return 'or-unchecked';
      }
    } else {
      if (tag.isActive) {
        return 'or-checked';
      } else {
        return 'or-unchecked';
      }
    }
  }

  getTagNameMaxWidth(): { 'max-width': string } {
    return { 'max-width': this.tagList?.nativeElement?.offsetWidth - 90 + 'px' };
  }

  toggle(tag: FloorplanTag): TagChangeEvent {
    tag.checked = tag.checked === CheckboxState.UNCHECKED ? CheckboxState.CHECKED : CheckboxState.UNCHECKED;
    const event = {
      tag,
      isActive: !tag.isActive,
      checked: tag.checked,
      isNodeTaggingModeActive: this.isNodeTaggingModeActive
    };
    this.tagSelectionChange.emit(event);
    return event;
  }

  reset(tag: FloorplanTag): void {
    this.tagReset.emit(tag);
    this.tagService.getTag(tag.id).subscribe((tagOld: Tag) => {
      tag.name = tagOld.name;
      tag.color = tagOld.color;
      tag.isDirty = false;
    });
  }
  createTag(tag: FloorplanTag): void {
    this.tagCreate.emit(tag);
  }

  updateTag(tag: FloorplanTag): void {
    this.tagUpdate.emit(tag);
  }

  tagChanged(exTag: FloorplanTag, tag: FloorplanTag): void {
    exTag.name = tag.name;
    exTag.color = tag.color;
    this.tagEditChange.emit(exTag);
  }

  isOfTenantType(tag: FloorplanTag): boolean {
    return tag.tagType === TagType.TENANT;
  }

  hasTenantPermission(): Observable<boolean> {
    return this.securityService.isAuthorizedForBuilding(BuildingAuthorityType.MANAGE_TENANT.value, this.buildingId);
  }

  deleteTag(tag: FloorplanTag): void {
    this.tagDeleted.emit(tag);
  }

  getNoTagStyle(): StatusClass {
    return StatusClass.WARNING;
  }
}

export class TagChangeEvent {
  tag: FloorplanTag;
  checked: CheckboxState;
  isActive: boolean;
  isNodeTaggingModeActive?: boolean;
}

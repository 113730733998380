<fieldset [disabled]="!isReady" class='shadow-md'>
  <app-panel [title]="'OFF &#8660; ON'" [icon]="'power_settings_new'">
    <div panel-content>
      <div class="w-[30%] block">
        <mat-slide-toggle color='primary' data-cy='beacon-enabled' [(ngModel)]="status"></mat-slide-toggle>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [dataCy]="'status'" [disableReset]="isBusy" (resetHandler)="resetStatus()" (updateHandler)="setStatus()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Power Level'" [icon]="'bolt'">
    <div panel-content>
      <div>
        <input class="or-input or-power-dbm" type="text" readonly id="dbm" data-cy="beacon-power-read" [(ngModel)]="powerLevel" />
        <input class="or-input or-no-shadow" type="range" min="0" max="6" data-cy="set-beacon-power"
        [(ngModel)]="indexedPower" (change)="convert()" value="0" />
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy" [dataCy]="'power'" (resetHandler)="resetPower()" (updateHandler)="setPowerLevel()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beacon Content'" [icon]="'content_paste_go'">
    <div panel-content>
      <div>
        <mat-select class="or-select" data-cy="set-beacon-content" [(ngModel)]="content">
          @for (item of contents; track $index) {
            <mat-option  [value]="item"> {{item.name}}</mat-option>
          }
        </mat-select>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy" [dataCy]="'content'" (resetHandler)="resetContent()" (updateHandler)="setContent()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beaconing Repetition'" [icon]="'repeat'">
    <div panel-content>
      <div>
        <input class="or-input or-no-shadow" type="range" min="0" max="2" data-cy="set-beacon-interval" [(ngModel)]="indexedInterval" list="steplist" />
        <datalist id="steplist">
          @for(item of intervals; track $index) {
            <option value="{{$index}}" label="{{item.name}}"></option>
          }
        </datalist>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy" [dataCy]="'interval'" (resetHandler)="resetInterval()" (updateHandler)="setInterval()"></app-panel-footer>
    </div>
  </app-panel>
  @if (showPanelBasedOnFeature) {
    <app-panel [title]="'UUID'" [icon]="'qr_code'">
      <div panel-content>
        <div>
          <mat-select class="or-select" data-cy="set-beacon-uuid" [(ngModel)]="uuid">
            @for (item of uuids; track $index) {
              <mat-option  [value]="item"> {{item.name}}</mat-option>
            }
          </mat-select>
        </div>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="isBusy" [dataCy]="'uuid'" (resetHandler)="resetUuid()" (updateHandler)="setUuid()"></app-panel-footer>
    </div>
    </app-panel>
    <app-panel [title]="'Major'" [icon]="'engineering'">
      <div panel-content>
        <div  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
            <input matInput class="or-input" type='number' data-cy="set-beacon-major" [(ngModel)]="major">
        </div>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="isBusy" [dataCy]="'major'" (resetHandler)="resetMajor()" (updateHandler)="setMajor()"></app-panel-footer>
    </div>

    </app-panel>
    <app-panel [title]="'Minor'" [icon]="'bookmark_remove'">
      <div panel-content>
        <div  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
          <input matInput class="or-input" type='number' data-cy="set-beacon-minor" [(ngModel)]="minor" disabled>
        </div>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="true" [dataCy]="'minor'" (resetHandler)="resetMinor()" (updateHandler)="setMinor()" [submitLabel]="'Generate'"></app-panel-footer>
    </div>
    </app-panel>
  }
  </fieldset>

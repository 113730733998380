import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@services/header.service';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { UserService } from '@services/user/user.service';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatestWith, Observable, Subject } from 'rxjs';
import { BuildingTileComponent } from '@app/buildings/building-tile/building-tile.component';
import { Building } from '@app/shared/models/building.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { BuildingMetadataService } from '@services/building-metadata/building-metadata.service';

const DEFAULT_TILE_WIDTH = 250;
const MARGIN_OFFSET = 16;

@Component({
  selector: 'app-buildings',
  standalone: true,
  imports: [CommonModule, SharedComponentsModule, BuildingTileComponent, AuthorizationModule],
  templateUrl: './buildings.component.html',
  styleUrl: './buildings.component.scss'
})
export class BuildingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') element: ElementRef;
  elementWidth = 250;
  loading$ = new BehaviorSubject<boolean>(true);
  private onDestroy$ = new Subject<void>();

  allBuildings: Building[] = [];
  filteredBuildings: Building[] = [];

  searchForm: FormGroup;

  @HostListener('window:resize')
  listenToResizeWindow(): void {
    this.resizeBuildingTile();
  }

  constructor(
    private headerService: HeaderService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private detector: ChangeDetectorRef,
    private buildingMetaService: BuildingMetadataService
  ) {}

  ngOnInit(): void {
    this.initSearchForm();
    this.headerService.hideFloorsMenu();
    this.headerService.showUserMenu();
    this.headerService.showSessionMenu();
    this.loadBuildings();
    this.initBuildingSearch();
  }

  ngAfterViewInit(): void {
    this.resizeBuildingTile();
    this.detector.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  resizeBuildingTile(): void {
    const width = this.element.nativeElement.offsetWidth;
    const elementCount = Math.floor(width / DEFAULT_TILE_WIDTH);
    this.elementWidth = DEFAULT_TILE_WIDTH - MARGIN_OFFSET + (width - DEFAULT_TILE_WIDTH * elementCount) / elementCount;
  }

  initSearchForm(): void {
    this.searchForm = this.fb.group({
      search: ''
    });
  }

  initBuildingSearch(): void {
    this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.filteredBuildings = this.filterBuildings(value.search);
    });
  }

  loadBuildings(): void {
    this.userService
      .getCurrentUser()
      .pipe(combineLatestWith(this.getBuildings()), takeUntil(this.onDestroy$))
      .subscribe((value) => {
        const user = value[0];
        const buildings = value[1];
        this.loading$.next(false);
        this.allBuildings = buildings;
        this.filteredBuildings = buildings;
        if (this.allBuildings.length === 1 && !user.globalAuthorities.includes(GlobalAuthority.ADD_BUILDINGS)) {
          console.log('Only have 1 building! and no add buildings');
          this.buildingMetaService
            .produceBuildingUrl(this.allBuildings[0])
            .subscribe((url) => this.router.navigateByUrl(url));
        }
      });
  }

  filterBuildings(queryValue: string): Building[] {
    return this.allBuildings.filter(
      (building) =>
        building.name.toLowerCase().includes(queryValue.toLowerCase()) ||
        building.id.toString().includes(queryValue.toLowerCase())
    );
  }

  getBuildings(): Observable<Building[]> {
    return this.userService.getBuildings().pipe(map((buildings) => buildings.sort((b1, b2) => b1.id - b2.id)));
  }

  openNewBuildingPage(): void {
    this.router.navigateByUrl('buildings/new').then(() => {});
  }
}

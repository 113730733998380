import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/shared/models/user.interface';
import { Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@app/shared/services/user/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input() public buildingsMenuActive$: Observable<boolean>;
  @Input() public floorsMenuActive$: Observable<boolean>;
  @Input() public sessionMenuActive$: Observable<boolean>;

  @Input() public user$: Observable<User>;

  public buildings$: Observable<Building[]>;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.buildings$ = this.userService.getBuildings();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { BacnetAreaTileComponent } from '@app/integrations/bacnet-area-tile/bacnet-area-tile.component';
import { ActivatedRoute, Params } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { BacnetAreaService } from '@services/bacnet-area.service';
import { BacnetArea } from '@app/shared/models/bacnet-area.interface';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { HeaderService } from '@services/header.service';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [MatProgressSpinner, CommonModule, BacnetAreaTileComponent, NotificationBlockComponent],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private bacnetAreaService: BacnetAreaService,
    private gatewayService: GatewayService,
    private headerService: HeaderService
  ) {}
  isBusy = true;
  isGatewaysLoaded = false;
  areas: BacnetArea[];
  newArea: BacnetArea;
  gateways: Gateway[];
  buildingId: number;
  StatusClass = StatusClass;

  ngOnInit(): void {
    this.headerService.showBuildingsMenu();
    this.headerService.showUserMenu();
    this.headerService.showSiteMenu();
    this.headerService.showSessionMenu();
    this.headerService.hideFloorsMenu();
    this.isBusy = true;
    this.route.params.subscribe((params: Params) => {
      this.buildingId = params.buildingId;
      this.loadAreas();
      this.loadGateways();
      this.isBusy = false;
    });
  }

  public loadAreas(): void {
    this.areas = null;
    this.bacnetAreaService.getAreas(this.buildingId).subscribe({
      next: (areas) => {
        this.areas = areas;
      }
    });
  }

  public loadGateways(): void {
    this.gatewayService.getGateways(this.buildingId).subscribe({
      next: (response) => {
        this.gateways = response.gateway;
        this.isGatewaysLoaded = true;
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { PanelComponent } from '@app/beacons/configuration/panel/panel.component';
import { PanelFooterComponent } from '@app/beacons/configuration/panel-footer/panel-footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { LightingConfigurationService } from '@services/lighting-configuration.service';
import { SensorNode } from '@app/shared/models/sensor-node';
import { ToastService } from '@services/toast/toast.service';
import { NodeType } from '@app/shared/models/types/node-type';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';

@Component({
  selector: 'app-lighting-config-panel',
  standalone: true,
  imports: [
    AuthorizationModule,
    MatOption,
    MatSelect,
    PanelComponent,
    PanelFooterComponent,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatSlider,
    MatSliderThumb
  ],
  templateUrl: './lighting-config-panel.component.html',
  styleUrl: './lighting-config-panel.component.scss'
})
export class LightingConfigPanelComponent {
  @Input() buildingId: number;
  @Input() floorId!: number;
  @Input() isReady: boolean;
  @Input() set selectedNode(node: SensorNode) {
    this.setNodeConfigValues(node);
  }
  private _selectedNode: SensorNode;
  isBusy = false;

  zone = -1;
  private originalZone: number;

  indexedMaxLight = 0;
  originalMaxLight: number;

  indexedLowLight = 0;
  private originalLowLight: number;

  indexedMinLight = 0;
  private originalMinLight: number;

  dwellTime: number;
  private originalDwellTime: number;

  lowLightTime: number;
  private originalLowLightTime: number;

  scene: number;
  private originalScene: number;

  constructor(
    private readonly toastService: ToastService,
    protected readonly lightingConfigService: LightingConfigurationService
  ) {}

  setNodeConfigValues(node: SensorNode): void {
    this._selectedNode = node;
    if (node?.lightingConfiguration) {
      const config = node.lightingConfiguration;
      this.zone = config.zone;
      this.indexedMaxLight = config.maxLightLevel;
      this.indexedLowLight = config.lowLightLevel;
      this.indexedMinLight = config.minLightLevel;
      this.dwellTime = config.dwellTime;
      this.lowLightTime = config.lowLightTime;
      this.scene = config.scene?.value || -1;
    } else {
      this.zone = -1;
      this.indexedMaxLight = this.lightingConfigService.DEFAULT_MAX_LIGHT;
      this.indexedLowLight = this.lightingConfigService.DEFAULT_LOW_LIGHT;
      this.indexedMinLight = this.lightingConfigService.DEFAULT_MIN_LIGHT;
      this.dwellTime = -1;
      this.lowLightTime = -1;
      this.scene = -1;
    }
    this.originalZone = this.zone;
    this.originalMaxLight = this.indexedMaxLight;
    this.originalLowLight = this.indexedLowLight;
    this.originalMinLight = this.indexedMinLight;
    this.originalDwellTime = this.dwellTime;
    this.originalLowLightTime = this.lowLightTime;
    this.originalScene = this.scene;
  }

  setZone(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateZone(this._selectedNode, this.zone, this.buildingId).subscribe({
        next: () => {
          this.originalZone = this.zone;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration zone has been set to ${this.zoneValue}`,
            dataCy: 'zone-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({ message: 'Failed to update zone', dataCy: 'failed-to-update-zone-toast' });
          this.isBusy = false;
        }
      });
    }
  }

  resetZone(): void {
    this.zone = this.originalZone;
  }

  get zoneValue(): string {
    return this.lightingConfigService.convertZoneIdToName(this.zone);
  }

  setMaxLight(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateMaxLight(this._selectedNode, this.indexedMaxLight, this.buildingId).subscribe({
        next: () => {
          this.originalMaxLight = this.indexedMaxLight;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration Max Light level has been set to ${this.maxLight}`,
            dataCy: 'max-light-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update Max light',
            dataCy: 'failed-to-update-max-light-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  resetMaxLight(): void {
    this.indexedMaxLight = this.originalMaxLight;
  }

  get maxLight(): string {
    return this.lightingConfigService.convertMaxLightIdToName(this.indexedMaxLight);
  }

  setLowLight(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateLowLight(this._selectedNode, this.indexedLowLight, this.buildingId).subscribe({
        next: () => {
          this.originalLowLight = this.indexedLowLight;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration Low Light level has been set to ${this.lowLight}`,
            dataCy: 'low-light-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update Low light',
            dataCy: 'failed-to-update-low-light-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  resetLowLight(): void {
    this.indexedLowLight = this.originalLowLight;
  }

  get lowLight(): string {
    return this.lightingConfigService.convertLowLightIdToName(this.indexedLowLight);
  }

  setMinLight(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateMinLight(this._selectedNode, this.indexedMinLight, this.buildingId).subscribe({
        next: () => {
          this.originalMinLight = this.indexedMinLight;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration Min Light level has been set to ${this.minLight}`,
            dataCy: 'min-light-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update Min light',
            dataCy: 'failed-to-update-min-light-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  resetMinLight(): void {
    this.indexedMinLight = this.originalMinLight;
  }

  get minLight(): string {
    return this.lightingConfigService.convertMinLightIdToName(this.indexedMinLight);
  }

  setDwellTime(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateDwellTime(this._selectedNode, this.dwellTime, this.buildingId).subscribe({
        next: () => {
          this.originalDwellTime = this.dwellTime;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration Dwell Light Time has been set to ${this.dwelTimeValue}`,
            dataCy: 'dwell-time-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update dwell time',
            dataCy: 'failed-to-update-dwell-time-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  resetDwellTime(): void {
    this.dwellTime = this.originalDwellTime;
  }

  get dwelTimeValue(): string {
    return this.lightingConfigService.convertTimeIdToName(this.dwellTime);
  }

  setLowLightTime(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateLowLightTime(this._selectedNode, this.lowLightTime, this.buildingId).subscribe({
        next: () => {
          this.originalLowLightTime = this.lowLightTime;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration Low Light Time has been set to ${this.lowLightTimeValue}`,
            dataCy: 'low-light-time-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update low light time',
            dataCy: 'failed-to-update-low-light-time-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  resetLowLightTime(): void {
    this.lowLightTime = this.originalLowLightTime;
  }

  get lowLightTimeValue(): string {
    return this.lightingConfigService.convertTimeIdToName(this.lowLightTime);
  }

  setScene(): void {
    if (this._selectedNode) {
      this.isBusy = true;
      this.lightingConfigService.updateScene(this._selectedNode, this.scene, this.buildingId).subscribe({
        next: () => {
          this.originalScene = this.scene;
          this.isBusy = false;
          this.toastService.success({
            message: `The lighting configuration scene has been set to ${this.sceneValue}`,
            dataCy: 'scene-updated-toast'
          });
        },
        error: (err) => {
          console.error(err);
          this.toastService.error({
            message: 'Failed to update scene',
            dataCy: 'failed-to-update-scene-toast'
          });
          this.isBusy = false;
        }
      });
    }
  }

  get isZoneDisabled(): boolean {
    return !LightingConfiguration.isZoneSupportedNode(this._selectedNode?.nodeType);
  }

  get sceneValue(): string {
    return this.lightingConfigService.convertSceneIdToName(this.scene);
  }

  resetScene(): void {
    this.scene = this.originalScene;
  }
}

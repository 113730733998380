import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Tab } from '@app/shared/components/tabs/tab.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { User } from '@app/shared/models/user.interface';
import { Building } from '@app/shared/models/building.interface';
import { globalAdministrationTabs } from '@app/global-administration/layout/global-administration-tabs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-global-administration',
  templateUrl: './global-administration.component.html',
  styleUrls: ['./global-administration.component.scss']
})
export class GlobalAdministrationComponent implements OnInit {
  public tabs$: Observable<Tab[]>;

  constructor(private readonly header: HeaderService, private readonly userService: UserService) {}

  ngOnInit(): void {
    this.header.showUserMenu();
    this.header.showSessionMenu();

    this.tabs$ = this.userService.getCurrentUser().pipe(
      map((user): Tab[] => {
        return this.createGlobalAdministrationMenu(user, null);
      })
    );
  }

  private createGlobalAdministrationMenu(user: User, building: Building): Tab[] {
    return globalAdministrationTabs.reduce<Tab[]>((tabs, currentTab) => {
      if (currentTab.isAuthorized(user, building)) {
        // If authorized add currentTab to tabs list
        return [...tabs, currentTab];
      }
      // Otherwise return previous tabs only
      return tabs;
    }, []);
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SensorNodeService } from '@app/shared/services/sensor-node.service';
import { SensorNodeDTO } from '@app/shared/models/sensor-node';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmDriver, SelectableEmDriver } from '@app/shared/models/em-driver';
import { FloorplanService } from '@services/floorplan.service';
import { IEmergencyTest } from '@app/shared/resources/emergency-lighting-test.resource';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { EmDriverService } from '@services/em-driver.service';
import { Tag } from '@app/shared/models/tag.interface';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { TagOutlineDirective } from '@components/floorplan/sensor-node/tag-outline.directive';

@Component({
  selector: 'app-em-driver',
  standalone: true,
  templateUrl: './em-driver.component.html',
  imports: [NgClass, NgStyle, TagOutlineDirective],
  styleUrls: ['./em-driver.component.scss']
})
export class EmDriverComponent implements OnInit, OnDestroy {
  @Input()
  driver: SelectableEmDriver;

  @Input()
  node: SensorNodeDTO;

  @Input()
  buildingId: number;

  @Input()
  isManualFunctionalTestModeActive: boolean;

  @Input()
  isManualDurationTestModeActive: boolean;

  @Input()
  isCancelTestModeActive: boolean;

  @Input()
  tags: Tag[] = [];

  hasTestBeenActivated = false;

  // this will be used to show information on tooltip
  resultPairForDriver: [IEmergencyTest?, IEmergencyTest?] = [];

  private onDestroy$ = new Subject<void>();
  // this will be used to style the driver
  private latestResultForDriver: IEmergencyTest;

  constructor(
    private readonly sensorNodeService: SensorNodeService,
    private floorplanService: FloorplanService,
    private toast: ToastService,
    private confirmDialogService: ConfirmationDialogService,
    private emDriverService: EmDriverService
  ) {}

  ngOnInit(): void {
    this.floorplanService.latestEmergencyResults
      .pipe(
        // at one point in time, results returned can be at least 0 test results and at most 2 test results
        map((results) => results.filter((result) => result.driverId === this.driver.id)),
        takeUntil(this.onDestroy$)
      )
      .subscribe((testResults: [IEmergencyTest, IEmergencyTest]) => {
        this.resultPairForDriver = testResults;
        this.latestResultForDriver = this.emDriverService.getValidLatestResult(testResults);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  showFunctionalTestResult(): string {
    const result = this.resultPairForDriver.find((r) => r.type.toString() === EmergencyLightingTestType.FUNCTION.value);
    return result ? EmergencyLightingTestState.fromValue(result.state.toString()).display : 'N/A';
  }

  showDurationTestResult(): string {
    const result = this.resultPairForDriver.find((r) => r.type.toString() === EmergencyLightingTestType.DURATION.value);
    return result ? EmergencyLightingTestState.fromValue(result.state.toString()).display : 'N/A';
  }

  shouldResultsBeVisible(): boolean {
    return this.floorplanService.isElmtPage;
  }

  private shouldRunTest(testType: string): Observable<boolean> {
    if (this.driver.inInitiatedTest) {
      this.toast.info({
        message: "Please wait until test progresses into 'running' state before starting a new test.",
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }
    if (this.node.isDriverEmergencyLightingTestStarted) {
      this.toast.info({
        message:
          'Another device in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting a new test.",
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }
    if (!this.hasRunningTest() && this.node.isTooManyDriverEmergencyLightingTest) {
      this.toast.info({
        message: 'Emergency lighting test for all devices cannot be run at the same time.',
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }
    this.confirmDialogService
      .open(
        new ConfirmDialogData(`Are you sure you wish to run a ${testType} test for this device?`, 'Confirm Test Start')
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          if (this.hasRunningTest()) {
            return this.confirmDialogService.open(
              new ConfirmDialogData(
                'A test is already running on this device.\r\n Running a new test cancels the current one. Continue Anyway?',
                'Confirm Test Start'
              )
            );
          } else {
            return of(true);
          }
        } else {
          return of(false);
        }
      });
  }

  private shouldCancelTest(): Observable<boolean> {
    if (!this.driver.activeTest) {
      this.toast.info({
        message: 'No functional or duration test is currently running for this device.',
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }
    if (!this.driver.inProgressTest) {
      this.toast.info({
        message:
          'It is not possible to cancel a test unless it is ‘running’.' +
          ' If the test does not transition to ‘running’ it will time out in approximately 2 minutes.',
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }
    if (this.node.isDriverEmergencyLightingTestStarted) {
      this.toast.info({
        message:
          'Another device in this setup has already started an emergency lighting test. Please wait ' +
          "until it progresses into 'running' state before starting or canceling a new test.",
        autoClose: false,
        dismissible: true,
        dataCy: `send-info-toast`
      });
      return of(false);
    }

    return this.confirmDialogService.open(
      new ConfirmDialogData('Are you sure you wish to cancel the running test for this device?', 'Confirm Test Cancel')
    );
  }

  private hasRunningTest(): boolean {
    return this.driver.inProgressTest || this.driver.inInitiatedTest;
  }

  get address16(): string {
    return this.driver.address.toString(16).toUpperCase();
  }

  onClick(event: MouseEvent): void {
    if (this.isManualFunctionalTestModeActive) {
      this.shouldRunTest('functional').subscribe((confirmed) => {
        if (confirmed) {
          this.emDriverService
            .startEmergencyLightingTest(this.buildingId, this.driver, EmergencyLightingTestType.FUNCTION)
            .subscribe(() => (this.hasTestBeenActivated = true));
        }
      });
    } else if (this.isManualDurationTestModeActive) {
      this.shouldRunTest('duration').subscribe((confirmed) => {
        if (confirmed) {
          this.emDriverService
            .startEmergencyLightingTest(this.buildingId, this.driver, EmergencyLightingTestType.DURATION)
            .subscribe(() => (this.hasTestBeenActivated = true));
        }
      });
    } else if (this.isCancelTestModeActive) {
      this.shouldCancelTest().subscribe((confirmed) => {
        if (confirmed) {
          this.emDriverService.cancelEmergencyLightingTest(this.buildingId, this.driver);
        }
      });
    } else if (this.floorplanService.isElmtPage) {
      this.sensorNodeService.toggleEntitySelection(this.driver, this.floorplanService.isCumulativeModeActive);
      this.sensorNodeService.updateQueryParams();
    }
    event.stopPropagation();
  }

  produceClassForEmDriver(): Record<string, boolean> {
    const classesWhenHighlighted: Record<string, boolean> = {};
    if (this.floorplanService.isHighlightEmergencyLightsModeActive) {
      classesWhenHighlighted.marked = true;
      classesWhenHighlighted.enabled = true;
    }

    const isDriverHidden = (): boolean => {
      let result = false;
      if (!this.floorplanService.isEnableAllNodesSelected) {
        result = true;
      }
      if (!this.floorplanService.isShowFaultyNodesModeActive && this.node.isFaulty) {
        result = true;
      }
      return result;
    };

    return {
      selected: this.driver.selected,
      'test-initiated': this.isTestInProgress(),
      'test-failed': this.hasTestFailed(),
      'test-cancelled': this.hasTestBeenCancelled(),
      'test-timed-out': this.hasTestTimedOut(),
      'test-passed': this.hasTestPassed(),
      'is-changed': this.node.isChanged,
      hidden: isDriverHidden(),
      ...classesWhenHighlighted
    };
  }

  private isTestInProgress(): boolean {
    return this.latestResultForDriver && EmDriver.isStateInProgress(this.latestResultForDriver.state.toString());
  }

  private hasTestBeenCancelled(): boolean {
    return this.latestResultForDriver && EmDriver.isStateCancelled(this.latestResultForDriver.state.toString());
  }

  private hasTestTimedOut(): boolean {
    return this.latestResultForDriver && EmDriver.isStateTimedOut(this.latestResultForDriver.state.toString());
  }

  private hasTestFailed(): boolean {
    return this.latestResultForDriver && EmDriver.isStateFailed(this.latestResultForDriver.state.toString());
  }

  private hasTestPassed(): boolean {
    return this.latestResultForDriver && EmDriver.isStatePassed(this.latestResultForDriver.state.toString());
  }

  generateDriverCoordinates(): Record<string, string> {
    const style: Record<string, string> = {};
    style.left = this.driver.x + 'px';
    style.top = this.driver.y + 'px';
    return style;
  }

  produceClassForEmDriverIcon(): {} {
    const styleClass = {};
    styleClass['or-icon-node'] = true;
    styleClass['or-icon-emergency'] = true;

    return styleClass;
  }

  getDriverIdText(): string {
    let text = 'Emergency Device';
    text += ' #' + this.driver.id;
    return text;
  }
}

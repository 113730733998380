import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dates } from '@app/analytics/metric-widget/query/outline/Dates';
import { QueryOutlineBuilder } from '@app/analytics/metric-widget/query/outline/QueryOutlineBuilder';
import { ComplexQueryOutline } from '@app/analytics/metric-widget/query/outline/ComplexQueryOutline';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { IQueryContext } from '@app/analytics/metric-widget/query/context';
import { defaultContext } from '@app/analytics/metric-widget/query/query-outline-builder-factory';
import { LiveQueryOutline } from '@app/analytics/metric-widget/query/outline/LiveQueryOutline';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsMetricsService {
  private metricContext = new BehaviorSubject<IQueryContext>(defaultContext);

  constructor() {}

  get currentContext$(): Observable<IQueryContext> {
    return this.metricContext.asObservable();
  }

  get currentContext(): IQueryContext {
    return this.metricContext.value;
  }

  set tagContext(tagIds: number[]) {
    const context = this.metricContext.value;
    context.tagIds = tagIds;
    this.metricContext.next(context);
  }

  updateNodeIs(nodeIds: number[]): void {
    const context = this.metricContext.value;
    context.sensorNodeIds = nodeIds;
    this.metricContext.next(context);
  }

  getCurrentContextDataType(): DataType {
    return this.metricContext.value.dataType;
  }

  updateContextBuilding(buildingId: number, floorId: number): void {
    if (buildingId !== this.metricContext.value.buildingId) {
      const newContext: IQueryContext = {
        ...this.metricContext.value,
        buildingId,
        floorIds: [floorId]
      };
      this.metricContext.next(newContext);
    }
  }

  updateContextDateAndResolution(dates: Dates, resolution: Resolution): void {
    const newContext = {
      ...this.metricContext.value,
      dates,
      resolution
    };
    this.metricContext.next(newContext);
  }

  updateContextDataType(dataType: DataType): void {
    const newContext = {
      ...this.metricContext.value,
      dataType
    };
    this.metricContext.next(newContext);
  }

  getNavigationOutline(): ComplexQueryOutline {
    return new QueryOutlineBuilder(this.metricContext.value).buildNavigationOutline();
  }

  getMainOutline(): ComplexQueryOutline {
    return new QueryOutlineBuilder(this.metricContext.value).buildMainOutline();
  }

  getLiveOutline(): LiveQueryOutline {
    return new QueryOutlineBuilder(this.metricContext.value).buildLiveOutline();
  }
}

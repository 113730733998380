import * as angular from 'angular'; // Automatically added
import { Map } from '@angularjs/or/util/Map';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { ColorUtils } from '@angularjs/or/util/ColorUtils';
import { ISecurityService } from '@angularjs/or/services/ISecurityService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { FilterService } from '@angularjs/or/services/FilterService';
import { Entity, UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { FeatureService } from '@app/shared/services/feature.service';
import { SensorNodeAlert } from '@angularjs/angular/modules/layout/or-sensor-node/SensorNodeAlert';

export class OrNodeTileController {
  public isBusy = false;
  public isCompact: boolean;
  public isExpanded = false;
  public details: FloorplanSensorNode;
  public onSelect;
  public scrollTo;
  public activeNode;
  private showHidden: boolean;
  private gatewayToColorWheelIndexMap: Map<number> = {};
  private isSelected;
  private alerts: SensorNodeAlert[];

  constructor(
    private $scope: ng.IScope,
    private $element,
    private securityService: ISecurityService,
    private nodesService: SensorNodeService,
    private filterService: FilterService,
    private uiRefreshService: UIRefreshService,
    private featureService: FeatureService
  ) {}

  public $onInit(): void {
    this.nodesService.getGatewayToSensorNodesMap().then((gatewayToNodesMap: Map<SensorNode[]>) => {
      let index = 0;
      for (const gatewayAddress in gatewayToNodesMap) {
        this.gatewayToColorWheelIndexMap[gatewayAddress] = index;
        index++;
      }
    });
    this.uiRefreshService.onChange(Entity.NODE, () => {
      this.isExpanded = false;
    });
    this.nodesService.selectedNodes.onChange((nodes) => {
      this.checkIfSelected(nodes);
    });
    this.checkIfSelected(this.nodesService.selectedNodes.value());
    this.nodesService.refreshFloorPlanNodes.subscribe(() => {
      setTimeout(() => {
        this.alerts = this.details.alerts;
      }, 10);
    });
    this.alerts = this.details.alerts;
  }

  private checkIfSelected(nodes): void {
    if (nodes) {
      let found = false;
      for (const item of nodes) {
        if (item.id === this.details.id) {
          found = true;
          if (this.scrollTo && this.$element) {
            this.scrollTo(this.$element);
          }
        }
      }
      this.isSelected = found;
    }
  }

  public get sortBy(): string {
    return this.filterService.sortBy;
  }

  public get reverse(): boolean {
    return this.filterService.reverse;
  }

  public toggleExpandedState(): void {
    if (!this.isExpanded && angular.isFunction(this.onSelect)) {
      this.onSelect();
    }

    if (
      (this.details.tags != null && this.details.tags.length > 0) ||
      (this.details.alerts != null && this.details.alerts.length > 0) ||
      (this.details.emDrivers != null && this.details.emDrivers.length > 0) ||
      (this.details.luminaireDrivers != null && this.details.luminaireDrivers.length > 0)
    ) {
      this.isExpanded = !this.isExpanded;
    }
  }

  public getForegroundColor(bgColor: string): any {
    return {
      'background-color': '#' + bgColor,
      color: ColorUtils.getConstrastingGrey(bgColor)
    };
  }

  /**
   * Get the classes to be applied on the node icon in the tile
   * @param {boolean} showMuted whether to show the icons as muted (low opacity)
   */
  public getNodeStatusAndTypeClass(showMuted?: boolean): string {
    const styleClass = 'or-node-tile-status-body or-node-tile-status-body-' + (this.details.status || 'unknown');

    switch (this.details.nodeType) {
      case SensorNode.HIM84_NODE_TYPE:
        return styleClass + ' him84 p-2';
      case SensorNode.HCD405_NODE_TYPE:
        return styleClass + ' hcd405 p-2';
      case SensorNode.HCD038_NODE_TYPE:
        return styleClass + ' hcd405 p-2';
      case SensorNode.PASSIVE_NODE_TYPE:
        return styleClass + ' passive-node' + (showMuted ? ' muted' : '');
      case SensorNode.FUJITSU_ENV_C02:
      case SensorNode.FUJITSU_ENV:
        return styleClass + ' environmental-node';
      default:
        return styleClass;
    }
  }

  public getNodeStatusIconClass(): string {
    const styleClass = 'or-icon or-small';
    switch (this.details.nodeType) {
      case SensorNode.HIM84_NODE_TYPE:
        return styleClass + ' or-icon-him84';
      case SensorNode.HCD405_NODE_TYPE:
        return styleClass + ' or-icon-hcd405';
      case SensorNode.HCD038_NODE_TYPE:
        return styleClass + ' or-icon-hcd405';
      case SensorNode.PASSIVE_NODE_TYPE:
        return styleClass + ' or-icon-passive-node';
      case SensorNode.FUJITSU_ENV_C02:
      case SensorNode.FUJITSU_ENV:
        return styleClass + ' or-icon-environmental-node';
      default:
        return styleClass + ' or-icon-node';
    }
  }

  public getClassIfHidden(): string {
    let hiddenClass = 'or-hide-hidden';
    if (this.showHidden) {
      hiddenClass = 'or-show-hidden';
    }
    return hiddenClass;
  }

  public getClassForTilesVisibility(): string {
    if (this.isBusy) {
      return 'or-hide';
    }
    return '';
  }

  public getSensorNodeBurnInHours(sensorNode: FloorplanSensorNode): number {
    if (
      sensorNode.luminaireDrivers &&
      sensorNode.luminaireDrivers.length &&
      sensorNode.luminaireDrivers[0].burnInHours
    ) {
      return sensorNode.luminaireDrivers[0].burnInHours;
    }
    return;
  }

  public getSensorNodeEnergyConsumption(sensorNode: FloorplanSensorNode): number {
    if (
      sensorNode.luminaireDrivers &&
      sensorNode.luminaireDrivers.length &&
      sensorNode.luminaireDrivers[0].energyConsumption
    ) {
      return sensorNode.luminaireDrivers[0].energyConsumption;
    }
    return;
  }

  public getSensorNodeBleScanning(sensorNode: FloorplanSensorNode): string {
    return sensorNode.bleScanning == null ? '??' : sensorNode.bleScanning;
  }

  protected get sensorNodeService(): SensorNodeService {
    return this.nodesService;
  }
}

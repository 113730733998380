import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { Observable, of, Subject } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-building',
  templateUrl: './new-building.component.html',
  styleUrls: ['./new-building.component.scss']
})
export class AppNewBuildingComponent implements OnInit {
  public details$: Observable<Building> = of(this.userService.produceCleanBuildingModel());
  public success$: Subject<void> = new Subject();

  constructor(private userService: UserService, private headerService: HeaderService, private router: Router) {}

  ngOnInit(): void {
    this.headerService.hideSiteMenu();
    this.headerService.showUserMenu();
    this.headerService.showSessionMenu();
    this.headerService.hideBuildingsMenu();

    this.success$.subscribe((building) => {
      this.router.navigate(['..']);
    });
  }
}

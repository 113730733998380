import { Component } from '@angular/core';
import { SecurityService } from '@services/security.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent {
  private permissionStored: { [authority: string]: Observable<boolean> } = {};

  constructor(private readonly securityService: SecurityService) {}

  public checkPermission(authority: string): Observable<boolean> {
    if (!this.permissionStored[authority]) {
      this.permissionStored[authority] = this.securityService.isAuthorized(authority).pipe(shareReplay(1));
    }
    return this.permissionStored[authority];
  }
}

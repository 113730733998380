import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-panel-footer',
  standalone: true,
  imports: [MatButton, MatTooltip, MatIcon],
  templateUrl: './panel-footer.component.html',
  styleUrl: './panel-footer.component.scss'
})
export class PanelFooterComponent {
  @Input() disableReset = false;
  @Input() disableSubmit = false;
  @Input() submitLabel = 'Update';
  @Input({ required: true }) dataCy: string;
  @Output() resetHandler = new EventEmitter();
  @Output() updateHandler = new EventEmitter();

  reset(): void {
    this.resetHandler.emit();
  }

  update(): void {
    this.updateHandler.emit();
  }
}

import { Component, Input } from '@angular/core';
import { Building, BuildingWarningState } from '@app/shared/models/building.interface';
import { BuildingMetadataService } from '@app/shared/services/building-metadata/building-metadata.service';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { SecurityService } from '@services/security.service';

@Component({
  selector: 'app-building-tile',
  standalone: true,
  imports: [CommonModule, SharedComponentsModule],
  templateUrl: './building-tile.component.html',
  styleUrls: ['./building-tile.component.scss']
})
export class BuildingTileComponent {
  @Input('details') public details: Building;

  constructor(private buildingMetadata: BuildingMetadataService, private securityService: SecurityService) {}

  produceBuildingUrl(building: Building): Observable<string> {
    return this.buildingMetadata.produceBuildingUrl(building);
  }

  produceBuildingThumbnailStyle(building: Building): Record<'background-image', string> {
    if (!building?.thumbnailImage) {
      return { 'background-image': 'none' };
    }
    const url = this.buildingMetadata.thumbnailUrl(building);
    return { 'background-image': `url(${url})` };
  }

  produceBuildingAddressString(buildingAddress): string {
    const rawAddressString = [
      buildingAddress.postcode,
      buildingAddress.addressLine1,
      buildingAddress.addressLine2,
      buildingAddress.addressLine3,
      buildingAddress.country
    ];
    const addressString = [];
    for (let idx = 0, len = rawAddressString.length; idx < len; idx += 1) {
      if (rawAddressString[idx] && rawAddressString[idx].length) {
        addressString.push(rawAddressString[idx]);
      }
    }
    return addressString.join(', ');
  }

  produceBuildingStateClass(buildingState: BuildingWarningState): string {
    if (buildingState == null) {
      return null;
    }

    if (BuildingWarningState[buildingState].toString() === BuildingWarningState.OK.toString()) {
      return 'or-building-status-ok';
    }
    if (BuildingWarningState[buildingState].toString() === BuildingWarningState.MODERATE.toString()) {
      return 'or-building-status-warning';
    }
    if (BuildingWarningState[buildingState].toString() === BuildingWarningState.SEVERE.toString()) {
      return 'or-building-status-critical';
    }
  }

  produceOfflineNodesClass(): string {
    if (this.hasFaultyNodes()) {
      return 'or-offline-nodes';
    } else {
      return null;
    }
  }

  hasFaultyNodes(): boolean {
    if (this.details.realTimeBuildingData && this.details.realTimeBuildingData.offlineNodeCount) {
      return this.details.realTimeBuildingData.offlineNodeCount > 0;
    }
    return false;
  }
}

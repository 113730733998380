import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagingCompanyService } from '@app/shared/services/managing-company.service';
import { ManagingCompanyDatasource } from '@app/global-administration/managing-company/managing-company.datasource';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManagingCompanyDialogComponent } from '@app/global-administration/managing-company/managing-company-dialog/managing-company-dialog.component';
import { Observable } from 'rxjs';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-managing-company',
  templateUrl: './managing-company.component.html',
  styleUrls: ['./managing-company.component.scss']
})
export class GlobalAdministrationManagingCompanyComponent implements OnInit, AfterViewInit {
  dataSource: ManagingCompanyDatasource;

  displayedColumns = ['id', 'companyName', 'actions'];
  companyName: string;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(
    private route: ActivatedRoute,
    private managingCompanyService: ManagingCompanyService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.dataSource = new ManagingCompanyDatasource(this.managingCompanyService);
    this.dataSource.loadManagingCompanies();
  }

  addManagingCompany(): void {
    const companyName = this.companyName;
    this.managingCompanyService.saveManagingCompany({ id: null, companyName }).subscribe({
      next: (data) => {
        this.dataSource.loadManagingCompanies();
        this.companyName = null;
        this.toastService.success({
          message: 'Created ' + data.companyName,
          dataCy: 'managing-company-success'
        });
      },
      error: (error) => {
        this.toastService.error({
          message: "Error when adding new Managing Company, please check the name doesn't already exist",
          dataCy: 'managing-company-create-error'
        });
      }
    });
  }

  editManagingCompany(managingCompany: ManagingCompany): void {
    this.openEditManagingCompanyDialog(this.dialog, managingCompany).subscribe((val) => {
      managingCompany.companyName = val.companyName;
      this.managingCompanyService.saveManagingCompany(managingCompany).subscribe({
        next: (data) => {
          this.toastService.success({
            message: 'Updated ' + data.companyName,
            dataCy: 'managing-company-update-success'
          });
        },
        error: (error) => {
          this.toastService.error({
            message: "Error when updating Managing Company, please check the name doesn't already exist",
            dataCy: 'managing-company-update-error'
          });
        }
      });
    });
  }

  deleteManagingCompany(managingCompany: ManagingCompany): void {
    const data = new ConfirmDialogData(
      `Delete managing company named: '${managingCompany.companyName}'?`,
      'Delete Managing Company'
    );
    this.confirmationDialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.managingCompanyService.deleteManagingCompany(managingCompany.id).subscribe({
          next: (data) => {
            console.log(data);
            this.toastService.success({
              message: 'Deleted ' + managingCompany.companyName,
              dataCy: 'managing-company-delete-success'
            });
            this.dataSource.loadManagingCompanies();
            this.companyName = null;
          },
          error: (error) => {
            this.toastService.error({
              message: 'Error when deleting Managing Company, please check it exist',
              dataCy: 'managing-company-delete-error'
            });
          }
        });
      }
    });
  }

  openEditManagingCompanyDialog(dialog: MatDialog, managingCompany: ManagingCompany): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = {
      ...managingCompany
    };
    config.width = '600px';
    const dialogRef = dialog.open(ManagingCompanyDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
